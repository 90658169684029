import React from "react";
import {useTranslation} from "react-i18next";
import {PromoSection} from "../../common/promo_section";
import {Badges} from "../../common/badges";
import {graphql, useStaticQuery} from "gatsby";
import GatsbyImage from "gatsby-image";

export const Introduction = () => {
  const {t} = useTranslation();

  const data = useStaticQuery(graphql`
  query {
  promo: file(relativePath: {eq: "promo/products-list.jpg"}) { ...promoImage } 
  }`)

  return (
     <PromoSection>
       <div className="page-promo-text">
         <h1>{t('products.hyperion.introduction.heading')}</h1>
         <p>{t('products.hyperion.introduction.description')}</p>
         <Badges />
       </div>
       <div className="page-promo-image">
         <div className="image-holder">
           <div className="image">
             <GatsbyImage
               style={{height: "100%", width: "100%"}}
               fluid={data.promo.childImageSharp.fluid}
             />
           </div>
         </div>
       </div>
     </PromoSection>
  );
};
