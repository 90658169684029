import React from "react";
import {NormalLayout} from "../../components/layouts/normal_layout";
import {SEO} from "../../components/seo";
import {Introduction} from "../../components/products/hyperion/introduction";
import {Products} from "../../components/products/hyperion/products";

const HyperionProducts: React.FC = () => {

  return (
      <NormalLayout type="product-list">
        <SEO title="Hyperion Line | UV Tech by Rendev" description="Hyperion Line | UV Tech by Rendev" />
        <Introduction />
        <Products />
      </NormalLayout>
  )
}

export default HyperionProducts;